.blueTheme{
    // .mobile-dashboard-header, 
    .cred-btn, 
    .carepay-table  th, 
    .dashboard-menu, 
    .mobile-menu-header,
    .border-bottom-heading,
    .accordion-button,
    .choose-file-input span,
    .detail-page-hr-tabs li {
        background-color: #0c3953;
    // background-image: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
    }
    .detail-page-heading, .choose-file-input .fa, .com-temp-heading{
        color: $deepBlue;
    }
    .detail-page-hr-sub-tabs li.active{
        border-bottom: 3px solid $deepBlue;
        background-color: black;
        color: #fff;
    }
}

.yellowTheme{
    .mobile-dashboard-header, 
    .cred-btn, 
    .carepay-table  th, 
    .dashboard-menu, 
    .mobile-menu-header,
    .border-bottom-heading,
    .accordion-button,
    .choose-file-input span,
    .detail-page-hr-tabs li {
        background-color: $accentYellow;
    }
    .detail-page-heading, .choose-file-input .fa, .com-temp-heading{
        color: $accentYellow;
    }
    .detail-page-hr-sub-tabs li.active{
        border-bottom: 5px solid $accentYellow;
    }
}

.paleBlueTheme{
    .mobile-dashboard-header, 
    .cred-btn, 
    .carepay-table  th, 
    .dashboard-menu, 
    .mobile-menu-header,
    .border-bottom-heading,
    .accordion-button,
    .choose-file-input span,
    .detail-page-hr-tabs li {
        background-color: $paleBlue;
    }
    .detail-page-heading, .choose-file-input .fa, .com-temp-heading{
        color: $paleBlue;
    }
    .detail-page-hr-sub-tabs li.active{
        border-bottom: 5px solid $paleBlue;
    }
}

.skyBlueTheme{
    .mobile-dashboard-header, 
    .cred-btn, 
    .carepay-table  th, 
    .dashboard-menu, 
    .mobile-menu-header,
    .border-bottom-heading,
    .accordion-button,
    .choose-file-input span,
    .detail-page-hr-tabs li {
        background-color: $info;
    }
    .detail-page-heading, .choose-file-input .fa, .com-temp-heading{
        color: $info;
    }
    .detail-page-hr-sub-tabs li.active{
        border-bottom: 5px solid $info;
    }
}

.blackTheme{
    .mobile-dashboard-header, 
    .cred-btn, 
    .carepay-table  th, 
    .dashboard-menu, 
    .mobile-menu-header,
    .border-bottom-heading,
    .accordion-button,
    .choose-file-input span,
    .detail-page-hr-tabs li {
        background-color: $dark;
    }
    .detail-page-heading, .choose-file-input .fa, .com-temp-heading{
        color: $dark;
    }
    .detail-page-hr-sub-tabs li.active{
        border-bottom: 5px solid $dark;
    }
}

.mudTheme{
    .mobile-dashboard-header, 
    .cred-btn, 
    .carepay-table  th, 
    .dashboard-menu, 
    .mobile-menu-header,
    .border-bottom-heading,
    .accordion-button,
    .choose-file-input span,
    .detail-page-hr-tabs li {
        background-color: $mud;
    }
    .detail-page-heading, .choose-file-input .fa, .com-temp-heading{
        color: $mud;
    }
    .detail-page-hr-sub-tabs li.active{
        border-bottom: 5px solid $mud;
    }
}

.pinkTheme{
    .mobile-dashboard-header, 
    .cred-btn, 
    .carepay-table  th, 
    .dashboard-menu, 
    .mobile-menu-header,
    .border-bottom-heading,
    .accordion-button,
    .choose-file-input span,
    .detail-page-hr-tabs li {
        background-color: $pink;
    }
    .detail-page-heading, .choose-file-input .fa, .com-temp-heading{
        color: $pink;
    }
    .detail-page-hr-sub-tabs li.active{
        border-bottom: 5px solid $pink;
    }
}