@import "./credhubTheme.scss";
@import "./Theme.scss";

@font-face {
  font-family: "product-sans-light";
  src: url("../Fonts/FontsFree-Net-ProductSans-Light.ttf");
}

$headerHeight: 55px;
$largeMenuWidth: 300px;
$smallMenuWidth: 40px;
$textcolor: #182d64;
$background: #f8f4ee;
$accent: #636363;
$highlight: #ff4a00;
$deepBlue: #007AFF;
$whitecolor: #fff;
$fieldrow: rgba(0, 117, 153, 0.2);
$lightblue: #d8ecf2;
$teal: #1f3f49;
$gradientBlue: #87cefa;
$limeyellow: #f5f580;
$google-blue: #4285f4;
$button-active-blue: #1669f2;

html,
body,
#carepayapp,
.full-height {
  height: 100% !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: $stone !important;
  // font-family: 'product-sans-light' !important;

  font-family: "product-sans-light", sans-serif !important;
  font-size: 13px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  // -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

label {
  font-size: 11px;
}

.form-control {
  font-size: 13px !important;
}

.dashboard-right {
  height: 100%;

  position: relative;
  padding-right: 0 !important;
  padding-left: 0 !important;

  .mobile-dashboard-header {
    background-color: #fff !important;
    border-bottom: 1px solid gray;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    // color: $whiteColor;
    height: $headerHeight;
    padding: calc(#{$headerHeight} / 4);
    display: flex;

    .logo {
      height: calc(#{$headerHeight} / 2);
    }

    .rightSection {
      flex-basis: 100%;
      flex: 1 1 25%;
      text-align: right;

      button {
        background: none;
        border: none;
        margin: 0 10px;
        line-height: 1;
        // color: $whiteColor;
        vertical-align: sub;
      }

      .user-icon {
        max-height: 26px;
        border-radius: 50%;
      }

      ul.dropdown-menu {
        margin-top: 15px !important;

        li {
          a {
            color: $blackColor;
            background-color: #fff !important;
            text-decoration: none;
          }
        }
      }
    }
  }

  .dashboard-body {
    padding: 15px;
    overflow: auto;
    background-position: right 156px;

  }
}

.white-bg {
  background-color: $whiteColor;
}

.full-width {
  width: 100%;
}

.cred-btn {
  color: $whiteColor;
  border: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.3rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  margin-right: 15px;

  &:hover {
    &:hover {
      background-color: #514C9F !important;
      color: $whiteColor !important;
      opacity: 0.8;
    }
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.cred-btn.active {
  opacity: 0.8;
}

.flex-center {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.login-main-page {
  background-image: url(../images/login-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .cred-login-page-new {
    width: 80% !important;
    height: 80%;
    border: none !important;

    .left-panel {
      background-color: $mud;
      padding: 30px;
      color: $mudLight;
      border: 4px solid $mudLight;
      border-right: none;

      .name {
        padding: 30px 0;
        font-size: 30px;
        text-align: center;
        text-transform: uppercase;
      }

      ul {
        li {
          font-size: 15px;
          padding: 10px 0;
        }
      }
    }

    .right-panel {
      background-color: $mudLight;
      border: 4px solid $mud;
      border-left: none;
      color: $mud;

      input {
        background-color: $mud;
        color: $mudLight;

        &:focus {
          box-shadow: none !important;
          outline: none !important;
          border: none !important;
        }
      }

      .cred-btn {
        background-color: $mud;
        color: $mudLight;
      }
    }
  }
}

.cred-login-page {
  position: relative;
  max-width: 700px;
  border-radius: 20px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);

  .left-panel {
    background-image: url("../images/logo.jpeg");
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    min-height: 400px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
    padding: 50px;
    background-size: 100%;
    background-repeat: no-repeat;
    border-right: 2px solid $stone;
    position: relative;

    .login-type {
      position: absolute;
      bottom: 0;
      left: 0;
      color: $blackColor;
      text-align: center;
      width: 100%;
    }
  }

  .left-panel.external {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .right-panel {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: $whiteColor;

    img {
      height: 60px;
      width: 190px;
      display: block;
      margin: 0 auto 10px auto;
    }
  }
}

.center-block {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.validation-msg {
  font-size: 12px;
  text-align: center;
  min-height: 15px;
  color: #dc3545;
  height: 15px;
}

.forgot-password {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  color: blue;
}

.carepay-table {
  width: 100%;
  font-size: 14px;
  overflow-x: scroll;
  background-color: $whiteColor;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);

  thead {
    color: $whiteColor;
    position: sticky;
    position: -webkit-sticky;
    z-index: 1;
    top: 0;

    th {
      white-space: nowrap;
      padding: 10px 5px !important;
      border: 1px solid $stone;
      padding: 5px 8px;
      text-align: center;

      .fa {
        font-size: 10px;
        margin-left: 5px;
      }
    }
  }

  td {
    border: 1px solid $stone;
    padding: 5px 10px !important;
    text-align: center;
    max-width: 400px;
    white-space: nowrap;
    overflow: auto;


    .action-btn {
      padding: 0;
      margin: 0 5px;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.multiselect {
  position: relative;

  .selectBox {
    position: relative;

    .product-list {
      display: inline-block;

      .list-item {
        background-color: $stone;
        padding: 0px 5px;
        border-radius: 5px;
        display: inline-block;
        margin-right: 5px;

        .btn-close {
          font-size: 8px;
          margin-left: 2px;
          outline: none;
          box-shadow: none;
        }
      }
    }

    input {
      display: inline-block;
      border: none;
      outline: none;
    }
  }

  .overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  #checkboxes {
    border: 1px #dadada solid;
    position: absolute;
    background-color: #fff;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 1000;
    padding: 0 10px;
    border-radius: 5px;
    max-height: 200px;
    overflow: auto;

    input[type="checkbox"] {
      vertical-align: middle;
    }
  }
}

.leftSection {
  flex-basis: 100%;
  flex: 1 1 75%;
  text-align: right;

  .header-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: sub;

    li {
      display: inline-block;
      padding: 0 20px;

      a {
        color: $whiteColor;
        text-decoration: none;
      }

      .dropdown-toggle {
        border: none;
        background: none;
        color: $whiteColor;
      }

      ul {
        li {
          display: block;

          a {
            color: $blackColor !important;
          }
        }
      }
    }

    li.active {

      a,
      button {
        opacity: 0.7;

      }
    }
  }
}
.carepay-loader{
  margin-top: 12%;
    position: fixed;
    margin-left: auto;
    margin-left: initial;
    // width: 100%;
    top: 40px;
    left: 46%;
}
// .credhub-loader {
//   position: relative;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   // background-color: $deepBlue20;
//   z-index: 1;

//   .loader-component {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     background-color: $whiteColor;
//     padding: 10px;
//     font-size: 30px;
//     border-radius: 4px;

//     .fa {
//       font-size: 40px;
//       margin-right: 10px;
//     }
//   }
// }

.dropdown-item {
  cursor: pointer;

  &:active {
    background-color: #e9ecef !important;
  }

  &:hover {
    color: #000 !important;
    background-color: #fff !important;
  }
}

.add-user-form {
  height: 100%;
  padding: 20px 0;
  overflow: auto;
  max-width: 700px !important;
}

.cancel-btn {
  // background-color: #514C9F !important;

  &:hover {
    background-color: #514C9F !important;
    color: $whiteColor !important;
    opacity: 0.8;
  }
}

.btn-close {

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none !important;
  }
}

.theme-page-popup {
  position: fixed !important;
  top: 0;
  bottom: 0;
  right: 0;
  width: 250px;
  z-index: 2;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      cursor: pointer;

      .theme-circle {
        display: inline-block;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        margin-right: 20px;
      }

      .blue-theme {
        background-color: $deepBlue;
      }

      .yellow-theme {
        background-color: $accentYellow;
      }

      .pale-blue-theme {
        background-color: $paleBlue;
      }

      .sky-blue-theme {
        background-color: $info;
      }

      .black-theme {
        background-color: $dark;
      }

      .black-theme {
        background-color: $dark;
      }

      .mud-theme {
        background-color: $mud;
      }

      .pink-theme {
        background-color: $pink;
      }

      label {
        vertical-align: super;
        cursor: pointer;
      }
    }
  }
}

.add-tool {
  background-color: $whiteColor;
  // height: 100%;
  padding: 20px 0;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
  border-radius: 4px;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);

  button {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.table-action-icons {
  height: 17px !important;
  cursor: pointer;
}

.dashboard-menu {
  height: 100%;
  padding: 15px;
  white-space: nowrap;
  overflow: auto;
  overflow-x: hidden;

  .menu-control-icon {
    line-height: 30px;
    cursor: pointer;
    font-size: 18px;
    color: #fff;
    text-align: center;
  }

  .menu-items {
    color: $whiteColor;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      line-height: 1;
      font-size: 14px;
      cursor: pointer;

      span {
        display: inline-block;
        padding-left: 20px;
      }

      a,
      button {
        color: $whiteColor;
        border: none;
        background: transparent;
        padding: 0;

        .fa {
          vertical-align: top;
          text-align: center;
          min-width: 14px;
        }
      }

      a.active {
        opacity: 0.7;
        color: #00ff3a;
      }

      .multi-collapse {
        list-style-type: none;

        li {
          padding: 5px 0;

          &:first-child {
            padding-top: 10px;
          }
        }
      }
    }

    .collapse-head {
      color: $whiteColor;
    }

    .collapse-head:not(.collapsed) {
      opacity: 0.7;
    }
  }

  .menu-items.Tablet {
    .tablet-sub-menu {
      position: absolute;
      left: 46px;
      background-color: $whiteColor;
      z-index: 9999;
      padding-left: 10px;
      border-radius: 4px;
      box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);

      li.tablet-menu-heading {
        color: $blackColor;
        text-align: center !important;
        display: block;
        font-weight: bold;
      }

      li {
        padding: 10px;
        text-align: left;
        border-bottom: 1px solid $stone;

        &:last-child {
          border-bottom: none;
        }

        a {
          color: $blackColor;
        }
      }
    }
  }

  .language-setting-main {
    color: $whiteColor;

    .text {
      font-size: 12px;
      padding-bottom: 10px;
      // padding-left: 12px;
    }

    button {
      padding: 4px 12px;
      height: 30px;
      border-radius: 4px;
      font-size: 15px;
      float: left;
      background: transparent;

      &.active {
        background: #4c54ad;
      }
    }
  }
}

.menu-large {
  // border-radius: 0px 0px 32px 0px;
  width: $largeMenuWidth !important;
  transition: 0.3s;

  .fa {
    min-width: 16px;
  }

  .logo {
    height: 40px;
    margin-top: 10px;
  }

  .menu-items {
    li {
      border-radius: 30px;
      padding: 10px 0px;
    }
  }

  .language-setting-main {
    .text {
      text-align: left;
    }
  }
}

.menu-small {
  border-radius: 0px;
  width: $smallMenuWidth !important;

  .menu-control-icon {
    width: 100%;
  }

  .logo {
    height: auto;
    margin: 0 auto;
    display: block;
  }

  .menu-items {
    li {
      border-radius: 15px;
      padding: 10px 0px;
      text-align: center;
      // span {
      //   display: none;
      // }
    }
  }

  .language-setting-main {
    .text {
      text-align: center;
    }
  }
}

.mobile-menu-main {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $whiteColor;
  z-index: 1000;

  .mobile-menu-header {
    color: $whiteColor;
    width: 100%;
    height: 100px;
    border-bottom-left-radius: 50% 25px !important;
    border-bottom-right-radius: 50% 25px !important;
    padding: 20px;

    .close-div {
      display: table;
      width: 100%;

      .btn-close {
        color: $whiteColor !important;
        opacity: 1;
        line-height: 1;
        font-size: 10px;
        float: right;
      }
    }
  }

  .mobile-menu-body {
    height: calc(100% - 100px);
    padding: 30px;
    overflow: auto;

    .name {
      font-size: 21px;
      white-space: nowrap;
      text-align: center;
      padding-top: 10px;
    }

    .menu-items {
      list-style-type: none;
      margin: 0;
      padding: 0;
      // margin-top: 60px;
      margin-bottom: 15px;

      li {
        line-height: 1;
        font-size: 16px;
        padding: 15px;
        cursor: pointer;
        border-radius: 25px;

        a,
        button {
          color: $blackColor;
          border: none;
          background: transparent;
          padding: 0;
          text-decoration: none;

          .fa {
            padding-right: 20px;
          }
        }

        a.active {
          opacity: 0.7;
          color: #007AFF;
        }

        .multi-collapse {
          list-style-type: none;

          li {
            padding: 5px 0;
          }
        }
      }

      .collapse-head {
        color: #000;
        text-decoration: none;
      }

      .collapse-head:not(.collapsed) {
        opacity: 0.7;
        color: #007AFF;
      }
    }

    .collapse {
      list-style-type: none;

      li.disabled {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .language-setting-main {
      padding: 0 15px;

      i {
        padding-right: 20px;
      }

      button {
        background: none;
        border: none;
        margin: 10px;
        border-radius: 8px;
        padding: 4px 12px;

        &.active {
          background-color: #dbdcef;
        }
      }
    }

    .logout-btn {
      padding: 12px 32px;
      background: #f3b300;
      border-radius: 24px;
      margin: 0 auto;
      display: block;
      margin-top: 15%;
    }
  }
}

.chart-main-page {
  display: flex;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
  border-bottom: 1px solid #ccc;

  .page-counts {
    position: absolute;
    font-size: 11px;
    left: 33px;
    top: 17px;
    font-weight: bold;
  }

  .step-heading {
    color: rgb(0, 0, 0);
    line-height: 50px;
    margin-bottom: 0;
    margin-left: 20px;
    font-weight: 700;
    font-size: 18px;

  }
}

.delete-popup-main {
  position: fixed;
  top: 20px;
  background-color: $whiteColor;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
  padding: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 5px;
  min-width: 250px;
  min-height: 100px;
  z-index: 99999;

  .text {
    font-size: 20px;
  }
}

.see-pdf-popup-main {
  position: fixed;
  top: 20px;
  background-color: $whiteColor;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
  padding: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 5px;
  min-width: 250px;
  min-height: 100px;
  z-index: 9999;

  .text {
    font-size: 20px;
  }
}

.pagination-container {
  display: flex;
  width: 100%;

  .left {
    width: 40%;

    .pagination-dd {
      border: none;
      outline: none;
      box-shadow: none;
      background: transparent;
    }
  }

  .right {
    width: 60%;

    ul {
      float: right;
    }
  }
}

.lender-detail-main-page {
  border: 1px solid $stone;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
  height: 100%;
  overflow: auto;
  padding: 15px;
  max-width: 600px !important;

  .detail-page-heading {
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid $stone;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}

.choose-file-input {
  cursor: pointer;
  display: block;
  position: relative;

  .fa {
    // color: $deepBlue;
    font-size: 50px;
    display: block;
  }

  span {
    // position: absolute;
    // top: 50%;
    // left: 120px;
    margin: 0 auto;
    max-width: auto;
    color: $whiteColor;
    padding: 5px 10px;
    border-radius: 4px;
    transform: translate(0, -50%);
  }
}

.font-size-18 {
  font-size: 18px;
}

// .border-bottom-heading {
// padding: 5px;
// border: none !important;
// background: #010066;
// color: #fff;
// font-size: 20px !important;
// font-weight: 700;
// cursor: pointer;
// }
.admin-accordion {
  .accordion-button {
    background: #f2f2fa !important;
    // color: #000 !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    padding: 7px !important;
    outline: none !important;

    border: none !important;
    cursor: pointer !important;
    // border-radius: 5px !important;
    box-shadow: none !important;

    &:active,
    &:visited {
      // background: $deepBlue !important ;
      outline: none !important;
      box-shadow: none !important;
      color: #fff !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  .accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }

  .accordion-item {
    outline: none !important;
    border: none !important;
    background-color: transparent;
    border: 4px solid transparent !important;


  }

  .accordion-header {
    border-radius: 5px !important;
    outline: none !important;
    border: none !important;
  }

  .accordion-button:not(.collapsed) {
    background: #000 !important;
    color: #fff !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.detail-page-hr-tabs {
  list-style-type: none;
  margin: 0;
  padding: 0px !important;
  border-bottom: 3px solid #636363;

  li {
    display: inline-block;
    cursor: pointer;
    color: $whiteColor;
    padding: 10px;
    opacity: 0.7;
  }

  li.active {
    opacity: 1;
  }
}

.detail-page-hr-sub-tabs {
  list-style-type: none;
  margin-left: 15px;
  padding: 0px !important;


  li {
    display: inline-block;
    cursor: pointer;
    padding: 5px;
    // background: rgb(242, 238, 238);
    min-width: 50px;
  }
}

.pointer {
  cursor: pointer;
}

.com-temp-heading {
  text-align: center;
  text-transform: capitalize;
}

.template-list {
  list-style-type: none;
}

.document-pdf {
  #my-pdf {
    width: 100% !important;
    height: calc(100% - 40px) !important;
    max-width: 100%;
    max-height: 100%;

    .react-pdf__Page {
      .canvaspdf {
        width: 100%;
      }
    }
  }
}

.mr-3 {
  margin-right: 3;
}

.tab-buttons {
  width: 100%;
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 5px;
  background-color: #fff;
  font-weight: 700;
  border: none;
  text-align: left;
  outline: none;

}

.active-tab {
  background-color: #F2F2FA !important;

}

.panel-default>.panel-heading {
  background-color: #000;
}

.menusideborder {
  border-right: 1px solid gray;
  min-height: 500px;
}

.button-templates {
  width: 100%;
  padding: 13px;
  box-shadow: rgba(152, 152, 209, 0.2) 0px 7px 29px 0px;
  background: $textcolor !important;
  color: #fff !important;
  cursor: pointer;
  // &:hover {
  //   background: $textcolor;
  //   color: #fff;
  // }
  // &:active {
  //   background: $textcolor !important;
  //   color: #fff !important;
  // }
}

.button-templates-active {
  cursor: pointer;
  background: #fff !important;
  color: #000 !important;
  width: 100%;
  padding: 13px;
  box-shadow: rgba(152, 152, 209, 0.2) 0px 7px 29px 0px;



}


.file-upload {
  background-color: #ffffff;
  width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.file-upload-btn {
  width: 100%;
  margin: 0;
  color: #fff;
  background: #1FB264;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid $textcolor;
  transition: all .2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.file-upload-btn:hover {
  background: #1AA059;
  color: #ffffff;
  transition: all .2s ease;
  cursor: pointer;
}

.file-upload-btn:active {
  border: 0;
  transition: all .2s ease;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  margin-top: 20px;
  border: 1px dashed #006fbc;
  background-size: 29%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 10px;
  background-image: url('../images/Upload icon (1).png');

}

.image-dropping,
.image-upload-wrap:hover {
  background-image: url('../images/Upload icon (1).png');
  border: 1px dashed green;
  color: #000;
}

.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}

.drag-text {
  text-align: center;
}

.drag-text h5 {
  font-weight: 100;
  text-transform: uppercase;
  color: grey;
  padding: 60px 0;
}

.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}

.remove-image {
  width: 200px;
  margin: 0;
  color: #fff;
  background: #cd4535;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 2px solid #b02818;
  transition: all .2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.remove-image:hover {
  background: #c13b2a;
  color: #ffffff;
  transition: all .2s ease;
  cursor: pointer;
}

.remove-image:active {
  border: 0;
  transition: all .2s ease;
}

.background-blur-admin {
  position: fixed;
  background: rgba(34, 31, 20, 0.7);
  z-index: 2;
  top: 55px;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}

.popup-admin {
  padding: 29px;
  color: #182d64;
  width: 35%;
  max-width: 100%;
  height: 42%;
  border-radius: 16px;
  margin-right: auto;
  margin-top: 7%;
  margin-left: auto;
  background: #ffffff;
  box-shadow: 6px 6px 60px rgba(0, 0, 0, 0.1);
}

.image-upload-wrap-multi {
  height: 55px;
  width: 55px;
  margin-top: 20px;
  border: 1px dashed $textcolor;
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 5px;
  background-image: url('../images/Upload icon (1).png');
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  cursor: pointer
}

.image-upload-wrap-multi-new {
  height: 55px;
  width: 55px;
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 5px;
  background-image: url('../images/icons8-attach-80.png');
  /* box-shadow: rgb(0 0 0 / 15%) 0px 15px 25px, rgb(0 0 0 / 5%) 0px 5px 10px; */
  cursor: pointer !important;
}

.file-upload-input-multi-new {
  position: absolute;
  margin: 0;
  padding: 0;
  height: 56px !important;
  width: 100px !important;
  outline: none;
  opacity: 0;
  cursor: pointer;

}

.drag-text-multi {
  margin-top: 30px;
  margin-left: 13px;
  font-size: 12px !important;

}

.file-upload-input-multi {
  position: absolute;
  margin: 0;
  padding: 0;
  height: 56px !important;
  width: 200px !important;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.income {
  .income-table {
    margin-top: 20px;
    width: 100%;

    table,
    th,
    td {
      border: 1px solid black;
      border-collapse: collapse;
    }

    th,
    td {
      padding: 5px;
      text-align: left;
    }

    th {
      background: $deepBlue;
      color: #fff;
    }
  }
}

.profile-image {
  border-radius: 17px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 150px;
  height: 150px;
  margin-top: 20px;
  // padding: 10px;
}

.profile-qr {
  border-radius: 17px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 150px;
  height: 150px;
  // padding: 10px;
}



#containerForMqc0x2VkgbncfWUmSvEJ7vFx28SotzbI,
#containerForhvtIKLEouf9Mvsh9LR5bmJlcbjchYmxm,
#containerFor6rXRQIit4Eny25UTmUOux7rn9FaUktvd {
  overflow: auto !important;
  background-size: 30px !important;
}

#mainTreeContainer {

  // min-width:850px;
  .tree-container {
    text-align: center;
    margin: 10px .5%;
    padding: 10px .5%;
    // background:#f7f7f7;
    float: left;
    // overflow:scroll;
    position: relative;

    &:first-child {
      padding-top: 20px;
    }

    &:first-child>.member:after {
      display: none;
    }
  }

  .member {
    // background: rgb(2,0,36);
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: center;
    background-image: url('../images/avatar.png');
    // background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgb(169, 169, 248) 35%, rgb(68, 92, 97) 100%);
    // border-radius: 50%;
    padding: 50px 0px 0px 0px;
    // padding: 5px;
    // height: 100px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    border-bottom: solid 1px #000;
    // color: #000;
    font-size: 10px;
    font-weight: 700;
    text-align: center;


  }

  .member:after {

    display: block;
    position: absolute;
    left: 50%;
    width: 1px;
    height: 20px;
    background: #000;
    content: " ";
    bottom: 100%;
  }

  .member:hover {
    z-index: 2;
  }

  .member .metaInfo {
    text-align: left;
    display: none;
    border: solid 1px grey;
    background: #fff;
    position: absolute;
    bottom: 20%;
    left: 25%;
    padding: 5px;
    min-width: 200px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }

  .member:hover .metaInfo {
    display: block;
  }

  .member .metaInfo img {
    width: 50px;
    height: 50px;
    display: inline-block;
    padding: 5px;
    margin-right: 5px;
    vertical-align: top;
    border: solid 1px #aaa;

  }
}

.document-box {
  padding: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  height: 350px;
  max-height: 350px;
  overflow: auto;
}

.css-19mk8g1-MuiInputBase-root-MuiFilledInput-root {
  background: transparent !important;
  background-color: transparent !important;
}

.css-67qocj-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root {
  background: transparent !important;
  background-color: transparent !important;

  &:active {
    background: #fff !important;
    background-color: #fff !important;
  }

  &:focus {
    background: #fff !important;
    background-color: #fff !important;
  }
}

.MuiFilledInput-underline,
.MuiInputBase-root,
.MuiInputBase-colorPrimary,
.Mui-error,
.MuiInputBase-formControl,
.MuiInputBase-sizeSmall,
.MuiInputBase-adornedEnd,
.css-g9m4e0-MuiInputBase-root-MuiFilledInput-root {
  background: transparent !important;
  background-color: transparent !important;

  &:active {
    background: #fff !important;
    background-color: #fff !important;
  }

  &:focus {
    background: #fff !important;
    background-color: #fff !important;
  }

}


.card-dashboard {
  position: relative;
  min-height: 80px;
  width: 100%;
  box-shadow: 5px 5px 10px #ccc;
  border: 1px solid #ccc;
  margin-top: 30px;
  border-radius: 5px;
  max-width: 220px;
  margin: 30px auto 0 auto;
}

.icon {
  position: absolute;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  top: -20px;
  color: #fff;
  border-radius: 5px;
  left: 15px;
  // box-shadow: 0 2px 9px 0 rgb(0 0 0 / 20%), 0 2px 13px 0 rgb(0 0 0 / 19%);
}

.heading-dashboard {
  float: right;
  margin: 5px 10px 0 81px;
  color: #999999;
  font-size: 12px;
}

.file-upload-show {
  // border: 1px solid lightgray;
  border-radius: 5px;
  padding: 4px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  width: 95%;
  margin-left: auto;
  margin-right: auto;

}

.background-blur-external {
  position: fixed;
  background: rgba(34, 31, 20, 0.7);
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: blur(3px);
}

.page-wrapper {
  cursor: pointer;
  top: 10%;
  padding: 0px 4.5px;
  height: 24px;
  width: 24px;
  font-size: 17px;
  height: 46;
  right: -7px;
  margin-right: 29px;
  position: fixed;
  // background: #f2483a;
  border-radius: 27px;
  z-index: 999;
  justify-content: center;
  // color: #fff;
}

.select-field-assign {
  border: none !important;
  border-bottom: 2px solid grey !important;
  border-radius: 0 !important;
  box-shadow: none !important;

  &:active {
    border: none !important;
    outline: none !important;
    border-bottom: 2px solid navy !important;
    box-shadow: none !important;

  }

  &:focus {
    border: none !important;
    outline: none !important;
    border-bottom: 2px solid navy !important;
    box-shadow: none !important;
  }
}

.date-input {

  &:focus,
  &:active {
    box-shadow: none !important;

  }
}

.communication-btn {
  background-color: #d7d7d7;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  margin-left: 2px;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  width: 118px;
  text-align: center;
  padding: 7px !important;

}

.communication-btn-active {
  background: $textcolor;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  margin-left: 2px;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  width: 118px;
  text-align: center;
  padding: 7px !important;

}

.pencil-edit {
  position: absolute;
  border: 1px solid grey;
  color: gray;
  padding: 2px 10px;
  border-radius: 25px;
  // left: 123px;
  right: 50px;
  top: 130px;
  background-color: #fff;

  .fa-pencil {
    font-size: 20px !important;
    margin-top: 5px;
    color: gray !important;
  }

}

.download-css {
  position: absolute;
  border: 1px solid grey;
  color: gray;
  padding: 2px 10px;
  border-radius: 25px;
  // left: 123px;
  right: 43px;
  top: 130px;
  background-color: #fff;

  .fa-download {
    font-size: 20px !important;
    margin-top: 5px;
    color: gray !important;
  }

}

.text-area-class {
  &:active {
    outline: none !important;
    // border:none !important
  }

  &:focus {
    outline: none !important;
    // border:none !important
  }
}

.card-Forums {
  padding: 10px !important;
  border-radius: 5px !important;
  background-color: #fff5f0 !important;
  min-height: 250px;
  max-height: 250px;
  overflow: auto;
  // background-image: url('../images/forumsBackground.jpg') !important;
  // background-repeat: no-repeat;
  // filter: grayscale(100%);


}

.fa-forum {
  text-align: right;
  // margin-top: 60px;
}

.forum-card-detail {
  padding: 15px !important;

}

.border-none {
  width: 100%;
  border: 1 px solid $textcolor !important;
  padding: 6px;

  &:active {
    outline: none !important;
    // border: none !important
  }

  &:focus {
    outline: none !important;
    // border: none !important
  }
}

.fa-beat {
  font-size: 20px !important;

  animation: fa-beat 5s ease infinite;
}

@keyframes fa-beat {
  0% {
    transform: scale(1);
  }

  5% {
    transform: scale(1.25);
  }

  20% {
    transform: scale(1);
  }

  30% {
    transform: scale(1);
  }

  35% {
    transform: scale(1.25);
  }

  50% {
    transform: scale(1);
  }

  55% {
    transform: scale(1.25);
  }

  70% {
    transform: scale(1);
  }
}

.fa-heart-icon {
  border: 1px solid red;
  color: #fff
}

.MuiInputLabel-asterisk {
  color: red !important;
  font-size: 18px !important;
}


input[type="email"] {
  text-transform: lowercase !important;

}

// textarea {

// text-transform: lowercase !important;

// }

.child-comment {
  width: 90%;
  padding: 10px;
  background-color: #f2f2f2;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 61px;
  font-size: 13px;
  border-top-left-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 50px;
  margin-left: 40px;
  font-weight: 700;
  color: #616161;

}

.cred-btn-forum {
  color: $whiteColor;
  border: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  padding: 0.3rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  margin-right: 15px;
  position: absolute !important;
  background-color: #000;
  bottom: 5px;
  right: 0;

}

input:read-only {
  background-color: #f5f5f5f5;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

label {
  z-index: 999 !important;
  // color: #00ff3a !important;
}

.css-10hburv-MuiTypography-root {
  font-size: 14px !important;
}

.icon-image {

  cursor: pointer;
  // position: absolute;
  right: 1;
  top: -2px;

}

.image-forum- {
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  height: 50% !important;
}

.comment-btn {
  margin-bottom: 20px;
  background-color: #ffff;
  color: grey;
  outline: none;
  border: none;
  float: 'right';
  margin-top: 18px;
  margin-right: 10px;
  padding: 10px;
  border-radius: 10px;

  &:hover {
    background-color: #ebebeb;
    color: #000;
  }
}

.parent-comment {

  background-color: #effbff;
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}


.edit-profile-menu {
  .nav {
    display: inline-block !important;

    a {
      width: 110px !important;
      padding: 5px !important;
    }
  }
}

.btn-group-tab {
  // margin: 1px 1px 10px 1px !important;
  background-color: #000;

  button {
    background: transparent !important;
    // border: 1px solid #fff;
    color: #fff;
    padding: 6px;
    width: auto;
    min-width: 100px;
    cursor: pointer;
    float: left;
    border: none !important;

    &:not(:last-child) {
      border-right: none;
    }

    &:hover,
    .active {
      background: #514C9F !important;
    }

    &:focus {
      outline: 0;
    }
  }

  &:after {
    content: "";
    clear: both;
    display: table;
  }

  .active {
    border-bottom: 6px solid #514C9F !important;
  }
}

.new-basic-head {
  font-size: 17px;
  background: $textcolor;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 600;
  padding: 3px 6px;
  margin-bottom: 4px;
  color: #fff;
}

.new-basic-head-nbfc {
  font-size: 17px;
  background: #221f1f;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 600;
  padding: 3px -1px;
  margin-bottom: 4px;
  color: #fff;
}


.profile-details-box-new {
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

  p {
    padding: 1px 10px;
  }
}

.designation-list {
  position: absolute;
  max-height: 300px;
  min-width: 90%;
  // height: 300px;
  top: 100%;
  // left: 15px;
  overflow: auto;
  background-color: #fff;
  z-index: 10;
  border: 1px solid #ccc;
  // box-shadow: 2px 2px 10px #ccc;
  font-size: 14px;
  list-style: none;

  li {
    white-space: nowrap;
    cursor: pointer;
  }
}

.badge {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px 10px;
  border-radius: 50%;
  background: red;
  color: white;
}

.admin-calc {
  .loan-input {
    background: #343a40 !important;
  }

  .loan-input input {
    background: #343a40 !important;
  }

  .div-blue {
    background: #cecece !important;
    color: #000 !important;
    border: none !important;
    padding: 7px 16px;
    top: -42px;
  }

  .MuiSlider-track {
    background: linear-gradient(92.39deg, #007bff 1.2%, #fff 98.7%) !important;

  }

  .thead-dark {
    position: sticky;
    top: 0
  }
}

.profile-image-outline {
  height: 150px;
  width: 150px;
  filter: drop-shadow(2px 2px 12px rgba(0, 0, 0, 0.12));
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 2px;
  overflow: hidden;
}

.currentprogress {

  .MuiSlider-rail,
  .MuiSlider-track {
    border-radius: 3px !important;
  }

  .MuiSlider-track {
    background: #267DFF !important;

  }

  .css-6cwnna-MuiSlider-mark {

    height: 10px !important;
  }
}

.dashboard-icons-div {
  width: 50px;
  height: 50px;
  border-radius: 16px;
  margin-top: -10px;
  float: right;
  text-align: center;
  margin-right: 10px;

  img {
    width: 24px;
    margin-top: 21%;
  }
}

.card-tour {
  position: fixed;
  top: 57px;
  right: 40px;
  min-height: 100px;
  width: 188px;
  background: #fff;
  z-index: 9999;
  border-radius: 10px;
  border: 1px solid #006fbc;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.2);
  opacity: 1;
  padding: 10px;

}

.background-blur-tour {
  position: fixed;
  background: rgba(34, 31, 20, 0.7);
  z-index: 0;
  top: 0;
  opacity: 0.999;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);

}

.arrow-class {
  width: 17%;
  transform: rotate(173deg);
  // margin-top: 10px;
}

.animat {
  -webkit-animation: slideIn 2s forwards;
  -moz-animation: slideIn 2s forwards;
  animation: slideIn 2s forwards;
}


@-webkit-keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

.paginationBtn {
  background: #0c3953;
  width: 100%;
  color: #fff;
  padding: 10px;
  font-size: 16px;
}

.paginationBtn-small {
  background: #aaaaaa;
  width: 100%;
  color: #000;
  padding: 10px;
  font-size: 16px;
}

.btn-group-tab-sub {
  background-color: rgb(114, 109, 109);

  button {
    background: transparent !important;
    // border: 1px solid #fff;
    color: #fff;
    padding: 6px;
    width: auto;
    min-width: 100px;
    cursor: pointer;
    float: left;
    border: none !important;

    &:not(:last-child) {
      border-right: none;
    }

    &:hover,
    .active {
      background: #0c3953 !important;
    }

    &:focus {
      outline: 0;
    }
  }

  &:after {
    content: "";
    clear: both;
    display: table;
  }

  .active {
    border-bottom: 6px solid #0c3953 !important;
  }
}

.comments-side-nav {
  button {
    font-size: 16px;
    font-weight: 900;

    span {
      font-size: 14px !important;
      color: gray !important;
    }

  }

  p {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

}

.chat-user {
  // cursor: pointer;
  padding: 3px 3px;

  &:hover {
    border-radius: 5px;
    background-color: $textcolor;
    color: #fff;

    .minimize-button {
      display: block;
    }

    button {
      color: #fff;

      span {
        font-size: 14px !important;
        color: #fff !important;
      }
    }

    margin-bottom: 2px;
  }

}

.side-chat-main {
  background: #f6f8fc;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;

}

.chat-user-active {
  .minimize-button {
    display: block;
  }

  border-radius: 5px;
  background-color: $textcolor;
  color: #fff;

  padding: 3px 3px;

  button {
    color: #fff;

    span {
      font-size: 14px !important;
      color: #fff !important;
    }
  }

  margin-bottom: 2px;

}

.minimize-button {
  display: none;
  cursor: pointer;
}

.chat-small-box {

  height: 400px;
  width: 300px;
  background: #f6f8fc;
  bottom: 20px;
  position: absolute;
  right: 0;
  // margin-bottom: 30px;

  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  overflow: scroll;
  z-index: 9999;
}

.header-chat {
  cursor: move;
  padding: 10px;
  background: #000;
  color: #fff;
  position: sticky;
  top: 0;

  i {
    float: right;
    cursor: pointer;
  }

  z-index: 9999;
}

.comment-div {
  height: 55px;
  bottom: 0;
  position: sticky;
  background-color: #fff;
  border-top: 1px solid gray;
  width: 293px;

  i {
    margin-top: 20px;
    cursor: pointer;
  }
}

@media (max-width: 768px) {

  .hide-small-chats {
    display: none !important;
  }
}

.chat-comment {
  position: absolute;
  // bottom: 30px;
  width: 100%;
}

.google {
  background-color: #dd4b39;
  color: white;
  padding: 12px 23px !important;

  &:hover {
    opacity: 0.5;
    color: #fff !important;
  }
}

.google-btn-new {
  width: 400px !important;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
}

.rs-notes--note__body {
  color: #000 !important;
  background-color: #fffccb !important;
  overflow: auto;
}

.rs-notes--text {
  color: #000 !important;

}

.rs-notes--navbar__item--button__upload {
  display: none !important;
}

.rs-notes--text:empty::before {
  color: #000 !important;
}

.hoverBorder {
  border: 1px solid #dee2e6;
}

.hoverBorder:hover {
  border: 3px solid #dee2e6;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.whatsapp-bg {
  background-color: #61a787 !important;
  background-image: url('../images/whatsappbg.png') !important;
  background-size: contain !important;
  background-position: center !important;

  &::before {
    opacity: 0.06;

  }
}

.upload_field input.wpcf7-file::-webkit-file-upload-button {
  border: none;
  color: #fff;
  // font-weight: 500;
  // background: linear-gradient(90deg, rgba(35,90,75,8) 0%, rgb(33, 169, 99) 35%, rgba(39,203,119,1) 100%);
  // padding: 4px 18px;
  // border-radius: 30px;
  // cursor: pointer;
  // box-shadow: 2px 1px 9px -3px #25c171;

}

.upload_field input.wpcf7-file::-webkit-file-upload-button:hover {
  // background-color: #61a787 !important;
  // background-image: url('../images/icons8-attach-16.png') !important;
  // background-size: contain !important;
  // background-position: center !important;
  // background: linear-gradient(90deg, rgba(39,203,119,1) 0%, rgba(39,203,119,1) 35%, rgba(14,90,51,1) 100%);
}

.upload_field input.wpcf7-file::-webkit-file-upload-button:focus {
  outline: none;
}

.upload_field input.wpcf7-file:focus {
  outline: none;
}

.upload_field {
  // margin-bottom: 20px;
  // padding-left: 5px;
  // // border: 1px solid #e6e6e6;
  // padding: 15px 10px 25px;
  border-radius: 20px;
  // background-color: #61a787 !important;
  background-image: url('../images/icons8-attach-16.png') !important;
  background-size: contain !important;
  background-position: center !important;
  content: '';
}

.noDecoration {
  text-decoration: none;
}
.list-rating{
.MuiRating-icon {
  font-size: 40px !important;
}
}
.MuiRating-icon {
  font-size: 25px !important;
}
.wrapper-new .tabs_wrap {

  // padding: 20px;
  // border-bottom: 1px solid #ebedec;
}

.wrapper-new .tabs_wrap ul {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.wrapper-new .tabs_wrap ul li {
  width: 135px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background: #e9ecf1;
  border-right: 1px solid #c1c4c9;
  // padding: 13px 15px;
  padding: 5px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.wrapper-new .tabs_wrap ul li:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.wrapper-new .tabs_wrap ul li:last-child {
  border-right: 0px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.wrapper-new .tabs_wrap ul li:hover,
.wrapper-new .tabs_wrap ul li.active {
  background: #514C9F;
  color: #fff;
}


.edit-profile-menu-new,
.coupon-details-menu {
  .dashboard-tabs {
    width: 222px;
    margin-left: 10px;
  }

  padding: 0;

  .nav {
    position: relative;
    z-index: 1;
    white-space: nowrap;
  }

  .nav a {
    position: relative;
    float: left;
    padding: 10px 22px 7px 22px;
    text-decoration: none;
    cursor: pointer;
    width: 140px;
    color: #000 !important;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .nav a::before {
    content: "";
    /* To generate the box */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-bottom: none !important;
    border: solid 1px #8f8dbd;
    border-radius: 10px 10px 0 0;
    // background-image: linear-gradient($whitecolor, $gradientBlue);
    // box-shadow: 0 2px hsla(0, 0%, 100%, 0.5) inset;
    transform: perspective(5px) rotateX(1deg);
    transform-origin: bottom left;
  }

  .nav a.selected {
    z-index: 2;
    color: #fff !important;


  }

  .nav a.selected::before {
    margin-bottom: -1px;
    border-top-width: 1px;
    padding: 0 5%;
    border-bottom: none !important;
    border: solid 1px $gradientBlue;
    background: #8f8dbd;
    overflow: auto;
  }
}

.background-purple {
  .nav a {
    content: "";
    /* To generate the box */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-bottom: none !important;
    border: solid 1px #8f8dbd;
    border-radius: 10px 10px 0 0;
    background: #8f8dbd;
    color: #fff;
    box-shadow: 0 2px hsla(0, 0%, 100%, 0.5) inset;
    transform: perspective(5px) rotateX(1deg);
    transform-origin: bottom left;
  }
}

.date-transaction {
  background: #ECEBFF;
  padding: 10px;
  border-radius: 5px;
}

.all-transaction-head {
  position: sticky;
  // z-index: ;
  background: #fff;
  top: -8px;
  z-index: 999;

}
.dashboard-body-color {
  padding: 15px;
  overflow: auto;
 background-color: #F2F5FC;

}
.list-doctor{
  
  width: 200px;
  list-style: none;
  overflow: scroll;
  height: 100px;
  position: fixed;
  z-index: 9;
  border: 1px solid grey;
  background: #fff;
li{
  cursor: pointer;
}

}
.card-communication {
  min-height: 400px;
  width: 100%;
  box-shadow: 5px 5px 10px #ccc;
  border: 1px solid #ccc;
  margin-top: 30px;
  border-radius: 5px;

}
.shadow-card {
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}